import { DateTime } from 'luxon'
import { has, includes, map } from 'lodash-es'

export default class Model {
  _attributes = {}
  _relationships = {}

  constructor (initialData, {
    columnNames = [
      // Key names of properties that are attributes of the model
      // e.g. ['name', 'description', 'created_at']
    ],
    dateKeys = [
      // Key names of properties that will be converted into Luxon objects
      // e.g. ['start_date', 'end_date']
    ],
    relationshipMap = {
      // Property names and the class object used to instantiate its model
      // e.g. { 'event': EventModelClass }
    },
    debug = false,
  }) {
    this._debug = debug



    // const fromApi = has(initialData, 'data')
    // const data = initialData.data ? initialData.data : initialData
    const data = initialData
    // const included = initialData.included

    // Create a getter for each column attribute
    for (let col of columnNames) {
      const attrSource = initialData
      // const attrSource = data.attributes ? data.attributes : initialData
      // Instantiate with Luxon if it is a date key.
      this._attributes[col] = includes(dateKeys, col)
        ? attrSource[col] && DateTime.fromISO(attrSource[col])
        : attrSource[col]
      Object.defineProperty(this, col, {
        get: () => this._getAttribute(col),
        set: val => this._setAttribute(col, val),
      })
    }

    for (let key of Object.keys(relationshipMap)) {
      const { collection, model } = relationshipMap[key]
      const Klass = model
      if (collection) {
        var relationData = map(data[key] ? data[key] : [], ref => {
          // return new Klass(included.find(i => i.type === ref.type && i.id === ref.id))
          return new Klass(ref)
        })
      } else {
        const ref = data[key] || null
        // var relationData = new Klass(included.find(i => i.type === ref.type && i.id === ref.id))
        if (ref) {
          var relationData = new Klass(ref)
        }
      }

      if (relationData) {
        this._relationships[key] = relationData
      }

      Object.defineProperty(this, key, {
        get: () => this._relationships[key]
      })
    }
  }

  get isProcessedModel () { return true }

  get attributes () { return this._attributes }
  get relationships () { return this._relationships }

  init () {
    for (let col in this.attributes) {
      Object.defineProperty(this, col, {
        get: () => this._getAttribute(col),
        set: val => this._setAttribute(col, val),
      })
    }
  }
  
  toHash () {
    return {
      ...this.attributes,
      ...this.relationships,
    }
  }

  // Created a temporary attribute on the model that is denoted by
  // prepending it with an underscore.
  addTemporaryAttribute (key, value) {
    const col = `_${key}`
    this._setAttribute(col, value)
    if (!this.col) {
      Object.defineProperty(this, col, {
        get: () => this._getAttribute(col),
        set: val => this._setAttribute(col, val),
      })
    }
  }

  _getAttribute (attr) { return this.attributes[attr] }
  _setAttribute (attr, val) { this.attributes[attr] = val }

  DEBUG () {
    if (this._debug) {
      console.log('DEBUG:', ...arguments)
    }
  }
}
