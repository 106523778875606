import BaseModel from '@models/Base'

export default class User extends BaseModel {
  constructor (initialData) {
    super(initialData, {
      columnNames: [
        'id',
        'first_name',
        'last_name',
        'last_login_at',
        'metadata',
        'created_at',
        'updated_at',
      ],
      dateKeys: [
        'last_login_at',
        'created_at',
        'updated_at',
      ],
      relationshipMap: {
        // roles: {
        //   model: RoleModel,
        //   collection: true,
        // },
      },
    })
  }

  get fullname () {
    return `${this.first_name} ${this.last_name}`
  }
}